<script>
import EmblaCarousel from "embla-carousel";
import HomeSectionTitle from "./components/home-section-title.vue";
export default {
  name: "HomeStoreReleaseSection",
  components: {HomeSectionTitle},
  props: {
    shops: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      emblaApi: null,
    }
  },
  mounted() {
    const emblaRef = this.$refs.emblaRef
    const options = { align: 'start', slidesToScroll: this.$device.isDesktop ? 4 : 2 }

    this.emblaApi = EmblaCarousel(emblaRef, options)
  },
  methods: {
    scrollPrev() {
      this.emblaApi?.scrollPrev()
    },
    scrollNext() {
      this.emblaApi?.scrollNext()
    }
  }
}
</script>
homeStoreReleaseSectionTitle
<template>
  <section class="flex flex-col items-center justify-center w-full bg-white border-b border-black">
    <HomeSectionTitle :title="$t('text.homePage.homeStoreReleaseSectionTitle')" :subtitle="$t('text.homePage.homeStoreReleaseSectionSubTitle')"/>
    <div class="relative w-full border-t border-[#131416]">
      <div ref="emblaRef" class="embla overflow-hidden">
        <div class="embla__container flex flex-row w-full flex-nowrap">
          <div class="embla__slide flex-none basis-1/2 md:basis-1/2 lg:basis-1/4 aspect-square border-r border-[#131416] last:border-r-0"
               v-for="(shop, i) in shops" :key="i" >
            <a :href="localePath({
            path: 'shops/' + shop.slug,
            })" class="flex items-center justify-center w-full h-full">
              <img :src="shop.logo" :alt="shop.name" class="w-40 h-40 object-contain"/>
            </a>
          </div>
        </div>
      </div>
      <button @click="scrollPrev" class="absolute  h-8 w-8 rounded-full left-0 top-1/2 -translate-y-1/2">
        <img src="~/assets/images/ui/home-swiper-next.png" alt="Previous" class="h-8 w-8 transform rotate-180"/>
      </button>
      <button @click="scrollNext" class="absolute   rounded-full right-0 top-1/2 -translate-y-1/2">
        <img src="~/assets/images/ui/home-swiper-next.png" alt="Previous" class="h-8 w-8"/>
      </button>
    </div>
  </section>
</template>
