<script>
import UAccordion from "~/components/ui/accordion.vue";
import ProductInfoRelated from "~/components/new/product/product-info-related.vue";
import UAccordionItem from "~/components/ui/accordion-item.vue";
import ProductDetailTable from "~/components/new/product/product-detail-table.vue";
import ProductInfoReturnPolicy from "~/components/new/product/product-info-return-policy.vue";
import ProductCheckoutButton from "@/components/new/product/product-checkout-button.vue";
import prices from "../../Details-New/Tabs/Prices.vue";
import {currencySymbol, nFormat, numberFormat, priceFormat} from "@/lib/utils";
import UDialogContent from "@/components/ui/dialog-content.vue";
import UButton from "@/components/ui/button.vue";
import redirectMixin from "@/plugins/redirectMixin";

export default {
  name: "product-info",
  props: {
    product: {
      type: Object,
      required: true,
    },
    relatedProducts: {
      type: Array,
      required: true,
    }
  },
  mixins: [redirectMixin],
  data() {
    return {
    }
  },

  methods: {
    priceFormat,
    goToDirect() {
      window.open(this.localePath({name: 'new-go',query: this.goProductQuery }), '_blank');
    },
    goToRelatedProduct() {
      const relatedWrap = document.getElementById('product-detail-related');
      const isDesktop = window.innerWidth > 1023;
      window.scrollTo({
        top: relatedWrap.offsetTop + (isDesktop ? -62 : 0),
        behavior: 'smooth'
      });
    },
    discount(price, salePrice) {
      return Math.round(((price - salePrice) / price) * 100) + "%";
    },
    goToCheckout(){
      console.log(this.encodeSize)
      this.$router.push({path: `/product/checkout/${this.product.id}?size=` + this.encodeSize})
    }
  },
  computed: {

    lnkProduct() {
      return {
        url: this.product.deeplink,
        productId: this.product.snkProductId || null,
        catalogProductId: this.product.id || null,
      }
    },
    brandName() {
      return this.product.catalog.brand.name.charAt(0).toUpperCase() + this.product.catalog.brand.name.slice(1);
    },
    releaseName() {
      return this.product.name.replace(this.product.catalog.brand.name, '');
    },
    shippingCountries() {
      return this.product.shop && this.product.shop.shipping_support_countries
        ? this.product.shop.shipping_support_countries.map((countryCode) => {
            return this.$t(`countries.common.${countryCode}`);
          }).join(', ')
      : "";
    },
    currentCountryName() {
      return this.$t(`countries.common.${this.$store.state.location.code}`);
    },
    prices() {
      return prices
    },
    isDiscount() {
      return this.product.exchange.price !== this.product.exchange.salePrice && this.product.exchange.salePrice > 0
    },
  },
  components: {
    UButton,
    UDialogContent,
    ProductCheckoutButton,
    ProductInfoReturnPolicy,
    ProductDetailTable, UAccordionItem, ProductInfoRelated, UAccordion}
}
</script>

<template>
  <div class="product-info">
    <div class="product-related-more" @click="goToRelatedProduct">
      <img src="~/assets/icons/ui/change-model.png" alt="product-related-more" class="product-related-more-icon"/>
      <span class="product-related-more-text">Change Model</span>
      <img src ="~/assets/icons/ui/arrow-right.svg" alt="product-related-more-arrow" class="product-related-more-arrow"/>
    </div>
    <div class="product-info-discount" v-show="isDiscount">
      <span>-{{discount(product.price, product.salePrice)}}</span>
    </div>
    <h2 class="brand-name">{{brandName}}</h2>
    <h1 class="product-name">{{releaseName}}</h1>
    <div class="product-price-only-sale" v-if="isDiscount">
      <div class="product-sale-price">{{product.exchange.salePriceDisplay }}</div>
      <div class="product-price">{{product.exchange.priceDisplay }}</div>
    </div>
    <div class="product-price-only-origin" v-else>
      <div class="product-price">{{product.exchange.salePriceDisplay }}</div>
    </div>
    <div class="product-sku">{{product.sku.sku}}</div>
    <div class="product-from-shop" v-if="product.shop">From. <span>{{product.shop.name}}</span></div>

    <div class="product-buy-buttons">
      <div class="product-related-button">
        <u-button color="black" :click="() => goToRelatedProduct()">{{$t('label.relatedProducts')}}</u-button>
      </div>
      <div class="product-buy-direct-button">
        <u-button color="secondary" :onDot="true" :click="() => goToDirect()">{{$t('label.buyDirect')}}</u-button>
      </div>
    </div>

    <div class="product-coupon-area" :class="{ 'show-area': product.crawlerId === 1 }">
      <div class="product-coupon-left-area"></div>
      <div class="product-coupon-right-area" @click="goToDirect">
        <div class="product-coupon-right-area-flag">Up to 70% Off SS24 Styles</div>
      </div>
    </div>

    <div class="product-button-divide">
      <div class="product-button-divide-or">OR</div>
      <div class="product-button-divide-description">
        <div>
          {{$t('text.productPage.shippingFeeOfThisProduct')}} : <span class="no-pointer">{{product.exchange.shippingFee ? product.exchange.shippingFeeDisplay : 'Free'}}</span>
        </div>
        <div v-html="$t('text.productPage.directDeliveryByShopMessage', {shopName: product.shop.name})" @click="() => goToDirect()">
        </div>
      </div>
    </div>

    <product-checkout-button :product="product" />
<!--    :shipping-countries="product.shipping_countries" check-country/>-->
<!--    <div class="product-memo">-->
<!--      <div >-->
<!--&lt;!&ndash;        v-if="product.is_shpping">&ndash;&gt;-->
<!--        {{$t('text.productPage.shippingFeeOfThisProduct')}} : <span>{{product.exchange.shippingFee ? product.exchange.shippingFeeDisplay : 'Free'}}</span>-->
<!--      </div>-->
<!--&lt;!&ndash;      <div v-else>&ndash;&gt;-->
<!--&lt;!&ndash;        {{$t('text.dontShip')}}<br/>&ndash;&gt;-->
<!--&lt;!&ndash;        {{$t('text.theCountryYouAreCurrentlyAccessing', {&ndash;&gt;-->
<!--&lt;!&ndash;        country: currentCountryName&ndash;&gt;-->
<!--&lt;!&ndash;      })}}&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--      <div v-html="$t('text.productPage.directDeliveryByShop', {shopName: product.shop.name})" @click="() => goToDirect">-->
<!--      </div>-->
<!--&lt;!&ndash;      <a target="_blank"&ndash;&gt;-->
<!--&lt;!&ndash;                            :href="localePath({name: 'go',query: query })"&ndash;&gt;-->
<!--&lt;!&ndash;                            class="external-redirect mt-4 block text-link"><b>[{{ product.shop.name }}]</b></a>.&ndash;&gt;-->
<!--    </div>-->


    <product-info-related :products="relatedProducts" />
    <u-accordion :style="{marginBottom: '44px', marginTop: '24px'}">
<!--      <u-accordion-item :title="$t('label.shippingSupportedCountries')">-->
<!--        <div class="product-info-shipping-support-countries">-->
<!--          {{ shippingCountries }}-->
<!--        </div>-->
<!--      </u-accordion-item>-->
      <u-accordion-item :title="$t('label.shippingReturnsPolicy')" :bold-title="true">
        <product-info-return-policy :product="product"/>
      </u-accordion-item>
      <u-accordion-item :title="$t('label.productDetails')" :bold-title="true">
        <product-detail-table :product="product"/>
      </u-accordion-item>
    </u-accordion>
  </div>
</template>

<style lang="scss" scoped>
.product-info {
  flex: 1;
  margin-right: 40px;
  .product-related-more {
    display: none;
  }
  .product-price-only-sale {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    height: 28px;
    .product-price {
      font-size: 20px;
      font-weight: normal;
      line-height: 30px;
      text-decoration: line-through;
      color: #191919;
    }
    .product-sale-price {
      font-size: 28px;
      font-weight: 800;
      line-height: 28px;
      color: #db3d46;
      margin-right: 6px;
    }
  }
  .product-info-discount {
    display: flex;
    span{
      display: block;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      height: 24px;
      color: #db3d46;
      margin-bottom: 8px;
      padding: 0 8px;
      border-radius: 4px;
      background-color: #ffceba;
    }
  }
  .brand-name {
    font-size: 18px;
    font-weight: 800;
    line-height: 18px;
    margin-bottom: 8px;
    color: #191919;
  }
  .product-name {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .product-price {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .product-sale-price {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .product-sku {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #191919;
    margin-bottom: 18px;
  }
  .product-from-shop{
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    color: #8999b1;
    margin-bottom: 12px;
    span {
      font-weight: normal;
      color: #191919;
    }
  }

  .product-buy-buttons {
    display: flex;
    flex-direction: row;
    .product-related-button {
      flex: 1;
      margin-right: 8px;
    }
    .product-buy-direct-button {
      flex: 1;
    }
  }

  .product-coupon-area{
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

    .product-coupon-left-area{
      display: none;
    }

    .product-coupon-right-area{
      display: none;
    }


    &.show-area{
      margin-top: 10px;
      .product-coupon-left-area{
        display: flex;
        flex: 1;
        margin-right: 8px;
      }
      .product-coupon-right-area{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        position: relative;
        cursor: pointer;
        .product-coupon-right-area-flag {
          background-color: #23d1d8;
          border-radius: 8px;
          color: #fff;
          display: block;
          font-weight: 700;
          padding: 8px 16px;
          text-align: center;
          width: 100%;
          font-size: 12px;

          &::after {
            border-color: #23d1d8 transparent;
            border-style: solid;
            border-width: 0 10px 10px;
            content: "";
            display: block;
            left: calc(50% - 10px);
            position: absolute;
            top: -8.5px;
            width: 0;
          }
        }

      }
    }
  }

  .product-button-divide{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    .product-button-divide-or{
      font-size: 12px;
      font-weight: normal;
      color: #6c7781;
      margin-bottom: 8px;
      flex: 1;
      text-align: center;
    }
    .product-button-divide-description{
      font-size: 12px;
      font-weight: normal;
      line-height: 22px;
      color: #6c7781;
      flex: 1;
      span{
        font-weight: 700;
        color: #6c7781;
        text-decoration: underline;
      }
    }
  }
  .product-memo {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #a0acc1;
    margin-bottom: 24px;
    span {
      font-weight: 700;
      color: #191919;
    }
    a {
      color: #191919;
      font-weight: 700;

      &:hover {
        color: #191919;
      }

      &:visited {
        color: #191919;
      }

      &:active {
        color: #191919;
      }

      &:focus {
        color: #191919;
      }
    }
  }


  /** mobile **/
  @media (max-width: 1023px) {
    margin-right: 16px;
    margin-top: 0;

    .product-related-more {
      display: flex;
      border-top: 1.5px solid #dfe6f0;
      border-bottom: 1.5px solid #dfe6f0;
      padding: 16px 10px;
      margin-bottom: 16px;
      margin-top: 0;
      align-items: center;

      .product-related-more-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      .product-related-more-text {
        flex: 1;
        font-size: 14px;
        font-weight: 800;
        color: #191919;
        margin-right: 8px;
      }

      .product-related-more-arrow {
        width: 7px;
        height: 11px;
      }
    }
  }

  .product-info-shipping-support-countries{
    padding: 0 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #191919;
    margin-bottom: 24px;
  }
  /** mobile **/
  @media (max-width: 767px) {
    margin-right: 0;

    .product-related-more {
      margin-top: 16px;
    }

    .product-buy-buttons {
      flex-direction: column;
      .product-related-button {
        display: none;
      }
    }
  }
}
</style>