<template>
  <div class="flex flex-col w-full">
<!--    <SimpleHeader />-->
    <u-container class="open-sans">
      <u-breadcrumbs :items="breadcrumbItems" />
      <div class="product-detail-wrap">
        <product-info :product="product" :related-products="relatedProducts"></product-info>
        <product-thumbnail :product="product"></product-thumbnail>
      </div>
    </u-container>
    <div class="related-product-wrap" id="product-detail-related" v-if="relatedProducts.length > 0">
      <u-container class="open-sans">
        <h1 class="product-related-title">
          {{ $t('text.productPage.youMightAlsoLike') }}
        </h1>
        <div>
          <product-related :products="relatedProducts"></product-related>
        </div>
      </u-container>
    </div>
  </div>
</template>

<script>
import SimpleHeader from "../../components/SimpleHeader";
import Breadcrumbs from "../../components/Breadcrumbs";
import Gallery from "../../components/product/Gallery.Vue";
import Info from "../../components/product/Info.Vue";
import ProductPrices from "../../components/Details-New/Tabs/Prices.vue";
import ProductInformation from "../../components/product/ProductInformation.vue";
import MobileProductDetail from "../../components/Details-New/Tabs/MobileProductDetail.vue";
import CompareProduct from "../../components/Details-New/Compare.vue";

import shippingMixin from '../../plugins/shippingMixin';
import {isEmpty} from "lodash-core";
import LinkParser from "../../components/linkParser";
import BestPrices from '../../components/Details-New/BestPrices.vue';
import redirectMixin from '../../plugins/redirectMixin.js';

import arrowRight from '../../components/icons/arrow-right.vue';
import staticMetasMixin from "@/plugins/staticMetasMixin";
import ProductInfo from "~/components/new/product/product-info.vue";
import UContainer from "~/components/ui/container.vue";
import UBreadcrumbs from "~/components/ui/breadcrumbs.vue";
import ProductRelated from "~/components/new/product/product-related.vue";
import ProductThumbnail from "~/components/new/product/product-thumbnail.vue";
import SearchHeaderLayout from "@/components/new/layouts/header/search-header.vue";
import axios from "axios";
import {objectToQueryString} from "~/lib/catalog-tools";

export default {
  name: "product_detail",
  validate ({ params }) {
    return params.id;
  },
  mixins: [shippingMixin, redirectMixin],
  components: {
    SearchHeaderLayout,
    ProductThumbnail,
    ProductRelated,
    UBreadcrumbs,
    UContainer,
    ProductInfo, LinkParser, SimpleHeader, Breadcrumbs, Gallery, Info, ProductPrices, ProductInformation, CompareProduct, MobileProductDetail, BestPrices, arrowRight },
  async asyncData({store, params, $axios, app, redirect, query})
  {
    const inputId = params.id;
    try {
      const country = (store.state.location.code || 'US').toUpperCase();
      const currency = (store.state.currency || 'USD').toUpperCase();

      //inputId에 - 가 있을 경우
      if (inputId.indexOf('-') > -1) {

        const catalogId = inputId.split('-')[0];
        const crawlerId = inputId.split('-')[1];

        const url = process.env.newApiUrlSsr + '/v1/products/catalogs/' + catalogId + '/crawlers/' + crawlerId;
        const resp = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'X-County-Code': country,
            'X-Currency-Code': currency,
            'X-Language-Code': app.i18n.locale,
            'X-Client-Uuid': store.state.clientUid || null,
          }
        });


        const data = resp.data;

        if (data && data.data) {
          return {
            product: data.data.product,
            relatedProducts: data.data.related,
            country, currency,
            language: app.i18n.locale};
        }

        throw new Error('Product not found');
      } else{

        const url = process.env.newApiUrlSsr + '/v1/products/snk/with-related/' + inputId;
        const resp = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'X-County-Code': country,
            'X-Currency-Code': currency,
            'X-Language-Code': app.i18n.locale,
            'X-Client-Uuid': store.state.clientUid || null,
          }
        });


        const data = resp.data;

        if (data && data.data) {
          return {
            product: data.data.product,
            relatedProducts: data.data.related,
            country, currency,
            language: app.i18n.locale};
        }

        throw new Error('Product not found');
      }
      throw new Error('Product not found');
    } catch (e) {
      //console.log(e);
      // throw e;
      if(inputId.indexOf('-') === -1) {

        if(app.i18n.locale === 'de') {
          redirect('/sneaker/');
        }else{
          redirect(`/${app.i18n.locale}/sneaker/`);
        }
      }
      redirect('/404');
    }
  },
  mounted() {
    // this.$store.commit('setCountry', this.country);
    // this.$store.commit('setCurrency', this.currency);
    // console.log('product', this.product);

    // this.fetchRelatedProducts();
  },
  data() {
    return {
      product: {},
      country          : 'US',
      currency         : 'USD',
      language         : 'en',
      currentImageIndex: 0,
      relatedProducts  : [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // Scroll to the top of the page after the component has been loaded
      vm.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    });
  },
  methods: {
    async fetchRelatedProducts() {

      const url = process.env.newApiUrl + '/v1/products/' + this.product.id + '/related';

      const {data} = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'X-County-Code': this.country,
          'X-Currency-Code': this.currency,
          'X-Language-Code': this.$i18n.locale,
          'X-Client-Uuid': this.$store.state.clientUid || null,
        }
      });

      if (data && data.data && data.data.length > 0) {
        // console.log(data.data);
        this.relatedProducts = data.data;
      }
    }
  },
  computed: {

    breadcrumbItems() {
      const product = this.product || {};
      const catalog = product.catalog || {};
      const name = product.name || '';
      const brand = catalog.brand.name || '';
      const category = catalog.categories && catalog.categories.length > 0 ? catalog.categories[0] : null;
      const sku = product.sku.sku || '';

      const breadcrumbs =  [
        {
          label: 'Home', href: this.localePath({name:'index'})
        },

        // {
        //   label: 'Sneakers', href: this.sneakerRootPage().path
        // },
        // {
        //   label: brand + ' Sneakers', href: this.brandPath(brand)
        // },
        //   ...(category && !isEmpty(category) ? [{
        //     label: category.title.replace(brand, '').trim() + ' Sneakers',
        //     href: this.productLocalePath(category.url)
        //   }] : []),

        {
          label: name + ' ' + sku,
          href: this.$route.path
        }
      ];

      return breadcrumbs;
    },
    fullName() {
      return this.product.catalog.brand.name + ' ' + this.product.name.replace(this.product.catalog.brand.name, '').trim();
    },
    pageTitle() {
      return this.fullName + ' ' + this.product.sku.sku;
    },
    pageDescription() {
      return `${this.fullName} (${this.product.sku.sku}}) | ${this.product.catalog.brand.name}, order easy & secure | Sneakers123.com`;
    },
    lnk() {
      return this.product.deeplink;
    },
  },
  head(){
    return {
      title: this.pageTitle,
      meta: [
        {hid: 'robots', name: 'robots', content: 'noindex,nocache,noarchive,follow'},
        {hid: 'AdsBot-Google', name: 'AdsBot-Google', content: 'index,follow'},
        {hid: 'og:title', property: 'og:title', content: this.pageTitle},
        {hid: 'description', name: 'description', content: this.pageDescription},
        {hid: 'og:description', property: 'og:description', content: this.pageDescription},
      ],
      __dangerouslyDisableSanitizers: ['script']
    }
  }
}
</script>

<style scoped lang="scss">
.product-detail-wrap {
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;

  /** mobile **/
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    margin-bottom: 30px;
  }
}

.related-product-wrap {
  width: 100%;
  min-height: 500px;
  background-color: #f4f5f9;

  .product-related-title {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 16px;
    margin-top: 30px;
    color: #191919;
  }
}
</style>