<script>
export default {
  name: "home-section-accordion",
  props: {
    items: {
      type: Array,
      default: []
    },
    useShowAccordion: {
      type: Boolean,
      default: false
    },
    showAccordion: {
      type: Boolean,
      default: false
    },
    onSelectShop: {
      type: Function,
      default: () => {}
    },
    setShowAccordion: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      innerShowAccordion: false,
      overItemIndex: null,
      titleItem: null,
      activeIndex: 0
    };
  },
  computed: {
    withoutTitleItems() {
      return this.items.filter((item) => item.id !== this.titleItem.id);
    },
  },
  created() {
    this.titleItem = this.items[this.activeIndex];
  },
  methods: {
    onAccordionClick(index) {
      // if (this.activeIndex === index) {
      //   return;
      // }

      this.activeIndex = index;
      this.titleItem = this.withoutTitleItems[index];
      this.onSelectShop(this.titleItem.id);
      if(this.useShowAccordion && !this.$device.isDesktop){
        this.$emit('setShowAccordion', false)
      }
    },
    onOverItem(index) {
      this.overItemIndex = index;
    },
    onLeaveItem() {
      this.overItemIndex = null;
    },
    onShowAccordion() {
      if(this.useShowAccordion) return;
      if(this.$device.isDesktop){
        this.innerShowAccordion = !this.innerShowAccordion;
      }
    },
    onHideAccordion() {
      if(this.useShowAccordion) return;
      if(this.$device.isDesktop){
        this.innerShowAccordion = false;
      }
    },
    toggleShowAccordion(){
      if(this.useShowAccordion) return;
      if(!this.$device.isDesktop){
        this.innerShowAccordion = !this.innerShowAccordion;
      }
    }
  },
}
</script>

<template>
  <div class="flex flex-col w-full" @mouseover="onShowAccordion" @mouseleave="onHideAccordion">
    <div :class="{
      'border-black border-t py-4 px-6 flex items-center justify-between  w-full relative': true,
      'underline': overItemIndex === null,
      'border-b': showAccordion
    }" @click="toggleShowAccordion">
      <span class="font-bold text-black text-lg w-full text-center">{{ titleItem.name }}</span>
      <div class="absolute right-6 top-1/2 transform -translate-y-1/2">
        <img src="~/assets/images/ui/icons/black-arrow-down.png" alt="arrow-down" class="w-[10px] h-[8px] transform transition-transform duration-500" :class="{'rotate-180': showAccordion}"/>
      </div>
    </div>
    <ul class="flex flex-col w-full h-[0px] overflow-hidden transition-all duration-500" :class="{'h-[300px]': showAccordion}">
      <li v-for="(item, index) in withoutTitleItems" :key="index" @mouseover="onOverItem(index)" @mouseleave="onLeaveItem()"
          @click="onAccordionClick(index)"
          class="flex flex-col w-full cursor-pointer border-black border-b"
      >
        <div class="py-4 px-6 flex items-center justify-between w-full">
          <span :class="{
            'text-black text-lg w-full text-center': true,
            'underline': overItemIndex === index,

          }">{{ item.name }}</span>
        </div>
      </li>

      <li class="flex flex-col w-full cursor-pointer" @mouseover="onOverItem(withoutTitleItems.length)" @mouseleave="onLeaveItem()">
        <nuxt-link :to="sneakerRootPage()" class="py-4 px-6 flex items-center justify-between w-full">
          <span :class="{
            'text-black text-lg w-full text-center': true,
            'underline': overItemIndex === withoutTitleItems.length,
          }">{{$t('text.homePage.moreStores')}}</span>
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>

</style>