import { render, staticRenderFns } from "./home-section-accodion.vue?vue&type=template&id=20ca9e6c&scoped=true"
import script from "./home-section-accodion.vue?vue&type=script&lang=js"
export * from "./home-section-accodion.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20ca9e6c",
  null
  
)

export default component.exports