<script>
import ReleaseFavorite from "./release-favorite.vue";
import ReleaseAlert from "./release-alert.vue";
import ProductPhotoRadio from "../../Details-New/ProductPhotoRadio.vue";
import EmblaCarousel from "embla-carousel";
import ProductThumbnail from "./product-thumbnail.vue";

export default {
  name: "release-detail-thumbnail",
  components: {ProductThumbnail, ProductPhotoRadio, ReleaseAlert, ReleaseFavorite},
  data() {
    return {
      emblaApi: null,
    }
  },
  props: {
    release: { type: Object, default: {} },
    currentImageIndex: { type: Number, default: 0 },
    imageHeight: { type: Number, default: 0 },
  },
  mounted() {
    const emblaRef = this.$refs.emblaRef
    const options = {
      containScroll: 'keepSnaps',
    }

    this.emblaApi = EmblaCarousel(emblaRef, options)
  },
  computed: {
    thumbImage() {
      if (this.currentImageIndex == 0) {
        return this.thumb(this.release, "big");
      } else {
        return this.release.gallery[this.$parent.currentImageIndex - 1];
      }
    },
  },
  methods: {
    scrollPrev() {
      this.emblaApi?.scrollPrev()
    },
    scrollNext() {
      this.emblaApi?.scrollNext()
    },
  }
}
</script>

<template>
  <div class="flex flex-col relative w-full">
    <div class="flex flex-row justify-between items-center">
      <div class="absolute top-4 left-4 lg:top-8 lg:left-8 z-10 text-gray-high text-xs">{{release.sku}}</div>
      <div class="absolute top-4 right-4 lg:top-8 lg:right-8 z-10 flex space-x-1">
        <release-alert :sneaker="release" v-show="!release.sold_out"></release-alert>
        <release-favorite :sneaker="release"/>
      </div>
    </div>
    <div class="flex flex-col w-full items-center justify-center flex-1">
      <div class="relative w-full">
        <div ref="emblaRef" class="embla overflow-hidden">
          <div class="embla__container flex flex-row w-full flex-nowrap">
            <div class="embla__slide">
              <img v-lazy-load class="object-contain object-center" :data-src="thumbImage" :alt="release.brand + ' ' + release.name + ' ' + release.sku" :style="{
            height: imageHeight + 'px',
            width: imageHeight + 'px',
          }" />
            </div>
            <template v-for="(img, index) in release.gallery">
              <div class="embla__slide">
                <img v-lazy-load class="object-contain object-center" :data-src="img" :alt="release.brand + ' ' + release.name + ' ' + release.sku" :style="{
            height: imageHeight + 'px',
            width: imageHeight + 'px',
          }" />
              </div>
            </template>
          </div>
          <client-only>

            <button @click="scrollPrev" class="absolute  h-8 w-8 rounded-full left-2 lg:left-8 top-1/2 -translate-y-1/2">
              <img src="~/assets/images/ui/icons/thumb-arrow-right.png" alt="Previous" class="h-8 w-8 transform rotate-180"/>
            </button>
            <button @click="scrollNext" class="absolute   rounded-full right-2 lg:right-8 top-1/2 -translate-y-1/2">
              <img src="~/assets/images/ui/icons/thumb-arrow-right.png" alt="Previous" class="h-8 w-8"/>
            </button>
          </client-only>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>