var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col relative w-full"},[_c('div',{staticClass:"flex flex-row justify-between items-center"},[_c('div',{staticClass:"absolute top-4 left-4 lg:top-8 lg:left-8 z-10 text-gray-high text-xs"},[_vm._v(_vm._s(_vm.release.sku))]),_vm._v(" "),_c('div',{staticClass:"absolute top-4 right-4 lg:top-8 lg:right-8 z-10 flex space-x-1"},[_c('release-alert',{directives:[{name:"show",rawName:"v-show",value:(!_vm.release.sold_out),expression:"!release.sold_out"}],attrs:{"sneaker":_vm.release}}),_vm._v(" "),_c('release-favorite',{attrs:{"sneaker":_vm.release}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-col w-full items-center justify-center flex-1"},[_c('div',{staticClass:"relative w-full"},[_c('div',{ref:"emblaRef",staticClass:"embla overflow-hidden"},[_c('div',{staticClass:"embla__container flex flex-row w-full flex-nowrap"},[_c('div',{staticClass:"embla__slide"},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"object-contain object-center",style:({
          height: _vm.imageHeight + 'px',
          width: _vm.imageHeight + 'px',
        }),attrs:{"data-src":_vm.thumbImage,"alt":_vm.release.brand + ' ' + _vm.release.name + ' ' + _vm.release.sku}})]),_vm._v(" "),_vm._l((_vm.release.gallery),function(img,index){return [_c('div',{staticClass:"embla__slide"},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"object-contain object-center",style:({
          height: _vm.imageHeight + 'px',
          width: _vm.imageHeight + 'px',
        }),attrs:{"data-src":img,"alt":_vm.release.brand + ' ' + _vm.release.name + ' ' + _vm.release.sku}})])]})],2),_vm._v(" "),_c('client-only',[_c('button',{staticClass:"absolute h-8 w-8 rounded-full left-2 lg:left-8 top-1/2 -translate-y-1/2",on:{"click":_vm.scrollPrev}},[_c('img',{staticClass:"h-8 w-8 transform rotate-180",attrs:{"src":require("assets/images/ui/icons/thumb-arrow-right.png"),"alt":"Previous"}})]),_vm._v(" "),_c('button',{staticClass:"absolute rounded-full right-2 lg:right-8 top-1/2 -translate-y-1/2",on:{"click":_vm.scrollNext}},[_c('img',{staticClass:"h-8 w-8",attrs:{"src":require("assets/images/ui/icons/thumb-arrow-right.png"),"alt":"Previous"}})])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }