<script>
import ReleaseThumbnail from "../../../thumbnails/release-thumbnail.vue";
import EmblaCarousel from "embla-carousel";

export default {
  name: "release-related",
  components: {ReleaseThumbnail},
  props: {
    relatedReleases: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      emblaApi: null,
    }
  },
  mounted() {
    const emblaRef = this.$refs.emblaRef
    const options = { align: 'start', slidesToScroll: this.$device.isDesktop ? 4 : 2 }

    this.emblaApi = EmblaCarousel(emblaRef, options)
  },
  methods: {
    scrollPrev() {
      this.emblaApi?.scrollPrev()
    },
    scrollNext() {
      this.emblaApi?.scrollNext()
    }
  }
}
</script>

<template>
  <section
      class="flex flex-col w-full border-b border-black"
  >
    <div class="flex flex-col w-full p-4 lg:p-8 border-b border-black">
      <h3 class="text-xl font-semibold text-[#131416] uppercase m-0">{{$t('text.youMightLike')}}</h3>
      <h4 class="text-xs text-[#131416] font-normal">{{$t('text.theHottestSneakersAtAGlance')}}</h4>
    </div>
    <div class="relative w-full">
      <div ref="emblaRef" class="embla overflow-hidden">
        <div class="embla__container flex flex-row w-full flex-nowrap">
          <div :class="{
          'embla__slide flex-none aspect-square border-r border-[#131416] last:border-r-0 basis-1/2 lg:basis-1/4': true,
        }"
               v-for="(release, i) in relatedReleases" :key="i" >
            <ReleaseThumbnail :release="release"/>
          </div>
        </div>
      </div>
      <button @click="scrollPrev" class="absolute  h-8 w-8 rounded-full left-0 top-1/2 -translate-y-1/2">
        <img src="~/assets/images/ui/home-swiper-next.png" alt="Previous" class="h-8 w-8 transform rotate-180"/>
      </button>
      <button @click="scrollNext" class="absolute   rounded-full right-0 top-1/2 -translate-y-1/2">
        <img src="~/assets/images/ui/home-swiper-next.png" alt="Previous" class="h-8 w-8"/>
      </button>
    </div>
  </section>
</template>

<style scoped>

</style>