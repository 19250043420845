<script>

import HomeSquareImage from "./components/home-square-image.vue";
import HomeSectionTitle from "./components/home-section-title.vue";

const genders = [
  {
    title: 'text.homePage.womenSneakers',
    link: '/sneaker/women',
    image: require('~/assets/images/ui/background/home-gender-women-bg.png')
  },
  {
    title: 'text.homePage.menSneakers',
    link: '/sneaker/men',
    image: require('~/assets/images/ui/background/home-gender-men-bg.png')
  }
];
export default {
  name: "HomeGenderSection",
  components: {HomeSectionTitle, HomeSquareImage},

  data() {
    return {
      selectedGenderIndex: 0,
      genders
    }
  },
  computed: {
    genderImage() {
      return genders[this.selectedGenderIndex].image
    },
  },
  methods: {
    selectGender(index) {
      this.selectedGenderIndex = index
    }
  }
}
</script>

<template>
  <section
      class="flex flex-col lg:flex-row w-full border-b border-black"
  >
    <HomeSquareImage position="left" :image="genderImage" k="gender"/>
    <div class="w-full lg:basis-1/2 lg:aspect-square flex flex-col justify-between">
      <HomeSectionTitle
          :title="$t('text.homePage.homeGenderSectionTitle')" :subtitle="$t('text.homePage.homeGenderSectionSubTitle')"/>
      <ul class="flex flex-col w-full">
        <li v-for="(gender, index) in genders" :key="index" @mouseover="selectGender(index)" class="border-t border-black  p-4 flex relative">
          <nuxt-link :to="localePath(gender.link)" :class="{
            'text-black text-md w-full flex-1 text-center': true,
            'no-underline': index !== selectedGenderIndex,
            'font-semibold underline': index === selectedGenderIndex
          }">
            {{$t(gender.title)}}
            <img src="~/assets/images/ui/icons/arrow-right.png" alt="arrow-right" class="absolute right-4 top-1/2 transform -translate-y-1/2 w-6 h-6"/>
          </nuxt-link>
        </li>
      </ul>
    </div>
  </section>
</template>